<!-- src/components/Example.vue -->
<template>
  <div class="bg-blue-500 text-white p-4">
    This is a Vue component styled with Tailwind CSS!
  </div>
</template>

<script>
export default {
  name: 'ExamplePage',
};
</script>

<style scoped>
/* Any additional component-specific styles go here */
</style>