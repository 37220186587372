<!-- src/components/Example.vue -->
<template>
  <div class="bg-blue-500 text-white p-4">
    This is a Vue component styled with Tailwind CSS!
    <button @click="redirectToAdminHome">Go to Admin Home</button>
  </div>
</template>

<script>
export default {
  name: 'AdminLogout',
  methods: {
    redirectToAdminHome() {
      // Assuming "AdminHome" is the name of your route
      this.$router.push({ name: 'AdminHome' });
    },
  },
};
</script>

<style scoped>
/* Any additional component-specific styles go here */
</style>
